import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NavMenu from './NavMenu';
import IconGroup from './IconGroup';
import MobileMenu from './MobileMenu';
import images from '../../../assets/imgs';

const HeaderWrapper = () => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const header = document.querySelector('.sticky-bar');
    setHeaderTop(header.offsetTop);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <section>
      <div>
        {/* <p className="header-superior text-center">
          PARCELE SEUS INGRESSOS EM ATÉ 12X PELO PAG SEGURO. DOADORES DE
          ALIMENTO PAGAM MEIA ENTRADA.
        </p> */}
      </div>
      <header
        className={`header-area sticky-bar header-padding-3 header-res-padding clearfix  ${
          scroll > headerTop ? 'stick' : ''
        }`}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-5 col-lg-6 d-none d-lg-block">
              {/* Nav menu */}
              <NavMenu menuWhiteClass="menu-white" />
            </div>
            <div className="col-xl-2 col-lg-2 col-md-6 col-4 logo-top">
              <div className="logo text-center logo-hm5">
                <Link className="sticky-none" to={process.env.PUBLIC_URL + '/'}>
                  <img
                    src={images.logo}
                    title="toinha-brasil-show"
                    alt="toinha-brasil-show-logo-400px-transparente"
                  />
                </Link>
                <Link
                  className="sticky-block"
                  to={process.env.PUBLIC_URL + '/'}
                >
                  <img
                    src={images.logo}
                    title="toinha-brasil-show-logo-400px-transparente"
                    alt="toinha-brasil-show-logo-400px-transparente"
                  />
                </Link>
              </div>
            </div>
            <div className="col-xl-5 col-lg-4 col-md-6 col-8">
              {/* Icon group */}
              <IconGroup iconWhiteClass="header-right-wrap-white" />
            </div>
          </div>
          {/* mobile menu */}
          <MobileMenu />
        </div>
      </header>
    </section>
  );
};

export default HeaderWrapper;
