import React from 'react';

const MobileWidgets = () => {
  return (
    <div className="offcanvas-widget-area">
      <div className="off-canvas-contact-widget">
        <div className="header-contact-info">
          <ul className="header-contact-info__list">
            <li>
              <i className="fa fa-phone"></i>{' '}
              <a href="tel://61 98616-5097">(61) 98616-5097</a>
            </li>
            <li>
              <i className="fa fa-envelope"></i>{' '}
              <a href="mailto:contato@toinhabrasilshow.com">
                contato@toinhabrasilshow.com
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/*Off Canvas Widget Social Start*/}
      <div className="off-canvas-widget-social">
        <a
          href="//www.instagram.com/toinhabrasilshow/"
          target="_blank"
          rel="noreferrer"
          title="Instagram"
        >
          <i className="fa fa-instagram" />
        </a>
        <a
          href="//www.facebook.com/toinhabrasilshow/"
          target="_blank"
          rel="noreferrer"
          title="facebook"
        >
          <i className="fa fa-facebook" />
        </a>
      </div>
      {/*Off Canvas Widget Social End*/}
    </div>
  );
};

export default MobileWidgets;
