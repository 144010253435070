import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';

import Router from './Router';
import ScrollToTop from '../hooks/useScrollTop';
import store, { persistor } from './store';

import SessionManager from './SessionManager';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SessionManager>
          <BrowserRouter>
            <ToastProvider placement="bottom-left">
              <ScrollToTop />
              <Router />
            </ToastProvider>
          </BrowserRouter>
        </SessionManager>
      </PersistGate>
    </Provider>
  );
}

export default App;
