import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const MobileNavMenu = () => {
  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      <ul>
        <li className="menu-item-has-children">
          <Link to={process.env.PUBLIC_URL + '/'}>{'Principal'}</Link>
        </li>

        <li className="menu-item-has-children">
          <Link to={process.env.PUBLIC_URL + '/eventos'}>{'Eventos'}</Link>
        </li>
        <li className="menu-item-has-children">
          <Link to={process.env.PUBLIC_URL + '/locacao'}>{'Locações'}</Link>
        </li>

        <li className="menu-item-has-children">
          <Link to={process.env.PUBLIC_URL + '/contato'}>{'Contato'}</Link>
        </li>
      </ul>
    </nav>
  );
};

MobileNavMenu.propTypes = {
  strings: PropTypes.object,
};

// export default multilanguage(MobileNavMenu);
export default MobileNavMenu;
