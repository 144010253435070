import { createAction } from 'redux-actions';
import ingressosApi from '../../utils/api/ingressosApi';
import { displayError } from './notificacoesActions';

export const resetIngressoDetalhesObject = createAction(
  'RESET_INGRESSO_DETALHES_OBJECT'
);

export const getClienteIngressosRequest = createAction(
  'GET_CLIENTE_INGRESSOS_REQUEST'
);
export const getClienteIngressosSuccess = createAction(
  'GET_CLIENTE_INGRESSOS_SUCCESS'
);

export const getClienteIngressosError = createAction(
  'GET_CLIENTE_INGRESSOS_ERROR'
);
export const getIngressoDetalhesRequest = createAction(
  'GET_INGRESSOS_DETALHES_REQUEST'
);
export const getIngressoDetalhesSuccess = createAction(
  'GET_INGRESSOS_DETALHES_SUCCESS'
);
export const getIngressoDetalhesError = createAction(
  'GET_INGRESSOS_DETALHES_ERROR'
);

export const getClienteIngressos =
  (clientId, page, pageSize) => async (dispatch) => {
    try {
      dispatch(getClienteIngressosRequest());
      const { data } = await ingressosApi.getClienteIngressos(
        clientId,
        page,
        pageSize
      );
      await dispatch(getClienteIngressosSuccess(data));
      return data;
    } catch (e) {
      console.log(e);
      dispatch(displayError(e));
      await dispatch(getClienteIngressosError(e));
      throw e;
    }
  };

export const getIngressoDetalhes = (ingressoId, userId) => async (dispatch) => {
  try {
    dispatch(getIngressoDetalhesRequest());
    const { data } = await ingressosApi.getIngressoDetalhes(ingressoId, userId);
    await dispatch(getIngressoDetalhesSuccess(data));
    return data;
  } catch (e) {
    console.log(e);
    dispatch(displayError(e));
    await dispatch(getIngressoDetalhesError(e));
    throw e;
  }
};

export const resetIngressoDetalhes = () => async (dispatch) => {
  await dispatch(resetIngressoDetalhesObject());
};
