import { createAction } from 'redux-actions';
import authApi from '../../utils/api/authApi';
import { displayError, displaySuccess } from './notificacoesActions';
import { createSession, destroySession, getSession } from '../../utils/session';
import { newCliente } from './clienteActions';

export const fetchUser = createAction('FETCH_USER');

export const signinRequest = createAction('SIGNIN_REQUEST');
export const signinSuccess = createAction('SIGNIN_SUCCESS');
export const signinError = createAction('SIGNIN_ERROR');

export const signoutRequest = createAction('SIGNOUT_REQUEST');
export const signoutSuccess = createAction('SIGNOUT_SUCCESS');
export const signoutError = createAction('SIGNOUT_ERROR');

export const forgotPasswordRequest = createAction('FORGOT_PASSWORD_REQUEST');
export const forgotPasswordSuccess = createAction('FORGOT_PASSWORD_SUCCESS');
export const forgotPasswordError = createAction('FORGOT_PASSWORD_ERROR');

export const resetPasswordRequest = createAction('RESET_PASSWORD_REQUEST');
export const resetPasswordSuccess = createAction('RESET_PASSWORD_SUCCESS');
export const resetPasswordError = createAction('RESET_PASSWORD_ERROR');

export const newSessionRequest = createAction('NEW_SESSION');

export const signin = (form, addToast) => async (dispatch) => {
  try {
    dispatch(signinRequest());

    const { data } = await authApi.signin(form);
    const session = { token: data.token, id: data.usuario._id };
    createSession(session);
    dispatch(signinSuccess(session));
    if (addToast) {
      addToast('Logado com sucesso!', {
        appearance: 'success',
        autoDismiss: true,
      });
    }
    return session;
  } catch (e) {
    console.log(e);
    dispatch(displayError(e));
    dispatch(signinError(e));
    if ((addToast && e && e.error) || (addToast && e.err)) {
      addToast(e.error || e.err, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    throw e;
  }
};

export const signout = () => async (dispatch) => {
  try {
    dispatch(signoutRequest());
    await authApi.signout();
    destroySession();
    dispatch(newCliente());
    dispatch(signoutSuccess());
    return true;
  } catch (e) {
    console.log(e);
    dispatch(signoutSuccess());
    dispatch(signoutError(e));
    dispatch(displayError(e));
    throw e;
  }
};

export const forgotPassword = (email, addToast) => async (dispatch) => {
  try {
    dispatch(forgotPasswordRequest());
    const { data } = await authApi.forgotPassword(email);
    dispatch(displaySuccess(data));
    dispatch(forgotPasswordSuccess(data));
    if (addToast) {
      addToast('E-mail enviado com sucesso!', {
        appearance: 'success',
        autoDismiss: true,
      });
    }
    return data;
  } catch (e) {
    console.error('forgot', e);
    dispatch(displayError(e));
    dispatch(forgotPasswordError(e));
    if ((addToast && e && e.error) || (addToast && e.err)) {
      addToast(e.error || e.err, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    throw e;
  }
};

export const resetPassword =
  (newPassword, resetPasswordLink, addToast) => async (dispatch) => {
    try {
      dispatch(resetPasswordRequest());
      const { data } = await authApi.resetPassword({
        newPassword,
        resetPasswordLink,
      });

      await dispatch(displaySuccess(data));
      await dispatch(resetPasswordSuccess(data));
      if (addToast) {
        addToast('Senha redefinida com sucesso!, realize o login', {
          appearance: 'success',
          autoDismiss: true,
        });
      }

      return data;
    } catch (e) {
      console.log(e);
      dispatch(displayError(e));
      await dispatch(resetPasswordError(e));
      if ((addToast && e && e.error) || (addToast && e.err)) {
        addToast(e.error || e.err, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
      throw e;
    }
  };

export const fetchUserData = () => (dispatch) => {
  const session = getSession();
  if (!session || !session.id || !session.token) throw Error('Sessão Inválida');
  dispatch(fetchUser(session));
};

// export const newSession = () => (dispatch) => {
//   dispatch(newSessionRequest());
//   dispatch(newCliente());
// };
