import { createAction } from 'redux-actions';
import clienteApi from '../../utils/api/clienteApi';
import authApi from '../../utils/api/authApi';
import { displayError } from './notificacoesActions';
import { JWT_KEY, NoIdProvidedException } from '../../utils/helpers';

export const getClienteRequest = createAction('GET_CLIENTE_REQUEST');
export const getClienteSuccess = createAction('GET_CLIENTE_SUCCESS');
export const getClienteError = createAction('GET_CLIENTE_ERROR');

export const createClienteRequest = createAction('CREATE_CLIENTE_REQUEST');
export const createClienteSuccess = createAction('CREATE_CLIENTE_SUCCESS');
export const createClienteError = createAction('CREATE_CLIENTE_ERROR');

export const updateClienteRequest = createAction('UPDATE_CLIENTE_REQUEST');
export const updateClienteSuccess = createAction('UPDATE_CLIENTE_SUCCESS');
export const updateClienteError = createAction('UPDATE_CLIENTE_ERROR');

export const newClienteRequest = createAction('NEW_CLIENTE_REQUEST');

export const createCliente = (form, addToast) => async (dispatch) => {
  try {
    dispatch(createClienteRequest());
    const { data } = await authApi.signup(form);
    dispatch(createClienteSuccess(data));
    if (addToast) {
      addToast('Cadastrado com sucesso!', {
        appearance: 'success',
        autoDismiss: true,
      });
    }
    return data;
  } catch (e) {
    console.log(e);
    dispatch(displayError(e));
    dispatch(createClienteError(e));
    if ((e && e.error) || e.err) {
      addToast(e.error || e.err, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    throw e;
  }
};

export const updateCliente = (userId, form, addToast) => async (dispatch) => {
  try {
    dispatch(updateClienteRequest());

    const { data } = await clienteApi.update(userId, form);
    dispatch(updateClienteSuccess(data));
    if (addToast) {
      addToast('Cadastrado atualizado sucesso!', {
        appearance: 'success',
        autoDismiss: true,
      });
    }
    return true;
  } catch (e) {
    console.log(e);
    dispatch(displayError(e));
    dispatch(updateClienteError(e));
    if ((e && e.error) || e.err) {
      addToast(e.error || e.err, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    throw e;
  }
};

export const getCliente = (id) => async (dispatch) => {
  if (!id) {
    return Promise.reject(new NoIdProvidedException());
  }

  try {
    dispatch(getClienteRequest());
    const { data } = await clienteApi.get(id);
    dispatch(getClienteSuccess(data));

    return true;
  } catch (e) {
    console.error(e);
    dispatch(displayError(e));
    dispatch(getClienteError(e));
    throw e;
  }
};

export const newCliente = () => async (dispatch) => {
  localStorage.removeItem(JWT_KEY);
  dispatch(newClienteRequest());
};
