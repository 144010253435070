import { get } from './base/index';

const ingressosApi = {
  getClienteIngressos: (clientId, page, pageSize) =>
    get(`/ingressos/usuario/${clientId}?page=${page}&pageSize=${pageSize}`),
  getIngressoDetalhes: (ingressoId, clientId) =>
    get(`/ingresso/usuario/${ingressoId}/${clientId}`),
};

export default ingressosApi;
