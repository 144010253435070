import { handleActions } from 'redux-actions';

import {
  fetchEventosRequest,
  fetchEventosSuccess,
  fetchEventosRangeRequest,
  fetchEventosRangeSuccess,
  fetchFilteredEventosRequest,
  fetchFilteredEventosSuccess,
  getEventoRequest,
  getEventoSuccess,
  getEventoError,
} from '../actions/eventosActions';

const defaultState = {
  eventos: [],
  evento: {},
  filteredEventos: [],
  eventosRange: [],
  totalPages: 0,
  totalItems: 0,
  isFetching: false,
  isFetchingEvento: false,
  error: null,
};

export default handleActions(
  {
    [fetchEventosRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchEventosSuccess](state, { payload }) {
      return {
        ...state,
        eventos: payload.docs,

        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [fetchEventosRangeRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchEventosRangeSuccess](state, { payload }) {
      return {
        ...state,
        eventosRange: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchFilteredEventosRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchFilteredEventosSuccess](state, { payload }) {
      return {
        ...state,
        filteredEventos: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [getEventoRequest](state) {
      return {
        ...state,
        evento: {},
        isFetchingEvento: true,
        error: null,
      };
    },
    [getEventoSuccess](state, { payload }) {
      return {
        ...state,
        evento: payload,
        isFetchingEvento: false,
        error: null,
      };
    },
    [getEventoError](state, { payload }) {
      return {
        ...state,
        isFetchingEvento: false,
        error: payload,
      };
    },
  },
  defaultState
);
