import { handleActions } from 'redux-actions';
import {
  createPedidosRequest,
  createPedidosSuccess,
  createPedidosError,
  getClientePedidosRequest,
  getClientePedidosSuccess,
  getClientePedidosError,
  getPedidoDetalhesRequest,
  getPedidoDetalhesSucess,
  getPedidoDetalhesError,
  calcularValorRequest,
  calcularValorSuccess,
  calcularValorError,
} from '../actions/pedidosActions';

const defaultState = {
  produtoNovo: {},
  listaDePedidos: {},
  pedidoDetalhes: {},
  calcular: {},
  isFetching: false,
  error: null,
};

export default handleActions(
  {
    [createPedidosRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [createPedidosSuccess](state, { payload }) {
      return {
        ...state,
        produtoNovo: payload,
        isFetching: false,
        error: null,
      };
    },
    [createPedidosError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [getClientePedidosRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [getClientePedidosSuccess](state, { payload }) {
      return {
        ...state,
        listaDePedidos: payload,
        isFetching: false,
        error: null,
      };
    },
    [getClientePedidosError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [getPedidoDetalhesRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [getPedidoDetalhesSucess](state, { payload }) {
      return {
        ...state,
        pedidoDetalhes: payload,
        isFetching: false,
        error: null,
      };
    },
    [getPedidoDetalhesError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [calcularValorRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [calcularValorSuccess](state, { payload }) {
      return {
        ...state,
        calcular: payload,
        isFetching: false,
        error: null,
      };
    },
    [calcularValorError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState
);
