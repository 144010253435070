import { handleActions } from 'redux-actions';
import { errorHandler, successHandler } from '../../utils/helpers';
import {
  displayErrorRequest,
  clearErrorRequest,
  displaySuccessRequest,
  clearSuccessRequest,
} from '../actions/notificacoesActions';

const defaultState = {
  error: null,
  success: null,
};

export default handleActions(
  {
    [displayErrorRequest](state, { payload }) {
      return {
        ...state,
        error: errorHandler(payload),
      };
    },
    [clearErrorRequest](state, { payload }) {
      return {
        ...state,
        error: null,
      };
    },
    [displaySuccessRequest](state, { payload }) {
      return {
        ...state,
        success: successHandler(payload),
      };
    },
    [clearSuccessRequest](state, { payload }) {
      return {
        ...state,
        success: null,
      };
    },
  },
  defaultState
);
