import showsReducer from './eventosReducer';
import cartReducer from './cartReducer';
import wishlistReducer from './wishlistReducer';
import ingressosReducer from './ingressosReducer';

import pedidosReducer from './pedidosReducer';
import clienteReducer from './clienteReducer';
import authReducer from './authReducer';
import cupomReducer from './cupomReducer';
import notificacoesReducer from './notificacoesReducer';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  eventosData: showsReducer,
  cartData: cartReducer,
  wishlistData: wishlistReducer,
  pedido: pedidosReducer,
  notificacoesReducer: notificacoesReducer,
  auth: authReducer,
  cliente: clienteReducer,
  ingressos: ingressosReducer,
  cupom: cupomReducer,
});

export default rootReducer;
