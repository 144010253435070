import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

/*----- Component Imports -----*/
import { Spinner } from 'reactstrap';

const Loading = ({ force, className }) => {
  const css = force ? { position: 'absolute', inset: '0 0 0 0' } : {};
  const loadingClass = classNames(className, 'loading-spinner');
  return (
    <div
      className="d-flex w-100 h-100 align-items-center justify-content-center"
      style={css}
    >
      <Spinner className={loadingClass} />
    </div>
  );
};

Loading.propTypes = {
  force: PropTypes.bool,
  className: PropTypes.any,
};
Loading.defaultProps = {
  force: false,
};

export default Loading;
