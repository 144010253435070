import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import MenuCart from './MenuCart';
import { signout } from '../../../redux/actions/authActions';

const IconGroup = ({ iconWhiteClass }) => {
  const ref = useRef();
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cartData = useSelector((state) => state.cartData);
  const wishlistData = useSelector((state) => state.wishlistData);
  const isLoggedIn = useSelector((state) => state.auth?.isLoggedIn);

  const handleClick = (e) => {
    e.currentTarget.nextSibling.classList.toggle('active');
  };
  const logout = async (e) => {
    await dispatch(signout());
    navigate('/');
  };

  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      '#offcanvas-mobile-menu'
    );
    offcanvasMobileMenu.classList.add('active');
  };

  //quando click fora do header,retira o active da cart... etc
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        const account = document.querySelector('.account-dropdown');
        const cart = document.querySelector('.shopping-cart-content');
        account.className = 'account-dropdown';
        cart.className = 'shopping-cart-content';
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
  //retira quando muda de pagina
  useEffect(() => {
    const account = document.querySelector('.account-dropdown');
    const cart = document.querySelector('.shopping-cart-content');
    account.className = 'account-dropdown';
    cart.className = 'shopping-cart-content';
  }, [pathname]);

  return (
    <div
      className={`header-right-wrap  ${iconWhiteClass ? iconWhiteClass : ''}`}
      ref={ref}
    >
      <div className="same-style account-setting d-lg-block">
        <button
          className="account-setting-active"
          aria-label="acessar conta"
          onClick={(e) => handleClick(e)}
        >
          <i className="pe-7s-user" />
        </button>
        <div className="account-dropdown">
          {isLoggedIn ? (
            <ul>
              <li>
                <Link to={process.env.PUBLIC_URL + '/cliente'}>
                  Minha Conta
                </Link>
              </li>
              <li onClick={logout} className="logout-button-hover">
                Sair
              </li>
            </ul>
          ) : (
            <ul>
              <li>
                <Link to={process.env.PUBLIC_URL + '/login'}>Login</Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + '/login'}>Register</Link>
              </li>
            </ul>
          )}
        </div>
      </div>
      <div className="same-style header-wishlist">
        <Link to={process.env.PUBLIC_URL + '/wishlist'}>
          <i className="pe-7s-like" />
          <span className="count-style">
            {wishlistData && wishlistData.length ? wishlistData.length : 0}
          </span>
        </Link>
      </div>
      <div className="same-style cart-wrap d-none d-lg-block">
        <button
          className="icon-cart"
          onClick={(e) => handleClick(e)}
          aria-label="acessar cart"
        >
          <i className="pe-7s-cart" />
          <span className="count-style">
            {cartData && cartData.length ? cartData.length : 0}
          </span>
        </button>

        <MenuCart cartData={cartData} />
      </div>
      <div className="same-style cart-wrap d-block d-lg-none">
        <Link className="icon-cart" to={process.env.PUBLIC_URL + '/cart'}>
          <i className="pe-7s-cart" />
          <span className="count-style">
            {cartData && cartData.length ? cartData.length : 0}
          </span>
        </Link>
      </div>
      <div className="same-style mobile-off-canvas d-block d-lg-none">
        <button
          className="mobile-aside-button"
          onClick={() => triggerMobileMenu()}
          aria-label="toggle menu"
        >
          <i className="pe-7s-menu" />
        </button>
      </div>
    </div>
  );
};

IconGroup.propTypes = {
  cartData: PropTypes.array,
  compareData: PropTypes.array,
  currency: PropTypes.object,
  iconWhiteClass: PropTypes.string,
  deleteFromCart: PropTypes.func,
  wishlistData: PropTypes.array,
};

export default IconGroup;
