import { handleActions } from 'redux-actions';
import {
  getClienteIngressosRequest,
  getClienteIngressosSuccess,
  getClienteIngressosError,
  getIngressoDetalhesRequest,
  getIngressoDetalhesSuccess,
  getIngressoDetalhesError,
  resetIngressoDetalhesObject,
} from '../actions/ingressosActions';

const defaultState = {
  listaDeIngressos: [],
  ingressoDeatalhe: {},
  totalPages: 0,
  totalItems: 0,
  isFetching: false,
  error: null,
};

export default handleActions(
  {
    [getClienteIngressosRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [getClienteIngressosSuccess](state, { payload }) {
      return {
        ...state,
        listaDeIngressos: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [getClienteIngressosError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [getIngressoDetalhesRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [getIngressoDetalhesSuccess](state, { payload }) {
      return {
        ...state,
        ingressoDeatalhe: payload,
        isFetching: false,
        error: null,
      };
    },
    [getIngressoDetalhesError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [resetIngressoDetalhesObject](state) {
      return {
        ...state,
        ingressoDeatalhe: {},
        isFetching: false,
        error: null,
      };
    },
  },
  defaultState
);
