import { createAction } from 'redux-actions';
import inscricaoEventoApi from '../../utils/api/pedidosApi';
import { displayError } from './notificacoesActions';

export const createPedidosRequest = createAction('CREATE_PEDIDOS_REQUEST');
export const createPedidosSuccess = createAction('CREATE_PEDIDOS_SUCCESS');
export const createPedidosError = createAction('CREATE_PEDIDOS_ERROR');

export const calcularValorRequest = createAction('CALCULAR_VALOR_REQUEST');
export const calcularValorSuccess = createAction('CALCULAR_VALOR_SUCCESS');
export const calcularValorError = createAction('CALCULAR_VALOR_ERROR');

export const getPedidoDetalhesRequest = createAction(
  'GET_PEDIDOS_DETALHES_REQUEST'
);
export const getPedidoDetalhesSucess = createAction(
  'GET_PEDIDOS_DETALHES_SUCCESS'
);
export const getPedidoDetalhesError = createAction(
  'GET_PEDIDOS_DETALHES_ERROR'
);

export const getClientePedidosRequest = createAction(
  'GET_CLIENTE_PEDIDOS_REQUEST'
);
export const getClientePedidosSuccess = createAction(
  'GET_CLIENTE_PEDIDOS_SUCCESS'
);
export const getClientePedidosError = createAction('GET_CLIENTE_PEDIDOS_ERROR');

export const createPedidos = (clientId, form) => async (dispatch) => {
  try {
    dispatch(createPedidosRequest());

    const { data } = await inscricaoEventoApi.createPedidos(clientId, form);
    await dispatch(createPedidosSuccess(data));

    return data;
  } catch (e) {
    console.log(e);
    dispatch(displayError(e));
    await dispatch(createPedidosError(e));
    throw e;
  }
};

export const getClientePedidos =
  (clientId, page, pageSize) => async (dispatch) => {
    try {
      dispatch(getClientePedidosRequest());
      const { data } = await inscricaoEventoApi.getClientePedidos(
        clientId,
        page,
        pageSize
      );
      await dispatch(getClientePedidosSuccess(data));

      return data;
    } catch (e) {
      console.log(e);
      dispatch(displayError(e));
      await dispatch(getClientePedidosError(e));
      throw e;
    }
  };

export const getPedidoDetalhes = (pedidoId, userId) => async (dispatch) => {
  try {
    dispatch(getPedidoDetalhesRequest());
    const { data } = await inscricaoEventoApi.getPedidoDetalhes(
      pedidoId,
      userId
    );
    await dispatch(getPedidoDetalhesSucess(data));

    return data;
  } catch (e) {
    console.log(e);
    dispatch(displayError(e));
    await dispatch(getPedidoDetalhesError(e));
    throw e;
  }
};

export const calcularValorPedido = (clientId, form) => async (dispatch) => {
  try {
    dispatch(calcularValorRequest());
    const { data } = await inscricaoEventoApi.calcularUsuarioPedido(
      clientId,
      form
    );
    await dispatch(calcularValorSuccess(data));

    return data;
  } catch (e) {
    console.log(e);
    dispatch(displayError(e));
    await dispatch(calcularValorError(e));
    throw e;
  }
};
