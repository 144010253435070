import axios from 'axios';
import { API } from '../../constants';
import { getSession } from '../../session';

export const defaultParams = () => {
  const token = getSession()?.token;
  return {
    baseURL: API,
    headers: {
      'Content-Type': 'application/json',
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
  };
};

export default axios;
