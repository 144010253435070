import { get } from './base/index';

export default {
  readEvento: (id) => get(`/evento/${id}`),
  getEventosList: (page, pageSize) =>
    get(`/eventos/by/disponiveis/?page=${page}&pageSize=${pageSize}`),
  getEventosRange: () => get('/eventos/by/proximas-datas/'),
  getFilteredEventosList: (dataInicio, dataFim) =>
    get(`/eventos/by/disponiveis/?dataInicio=${dataInicio}&dataFim=${dataFim}`),
};
