import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { deleteFromCart } from '../../../redux/actions/cartActions';
import { priceFormatter } from '../../../utils/helpers';
const MenuCart = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const cartData = useSelector((state) => state.cartData);
  const cartTotalPrice = cartData?.reduce(
    (accumalator, cartItem) => accumalator + cartItem.valor * cartItem.quantity,
    0
  );

  return (
    <div className="shopping-cart-content">
      {cartData && cartData.length > 0 ? (
        <Fragment>
          <ul>
            {cartData.map((single, key) => {
              return (
                <li className="single-shopping-cart" key={key}>
                  <div className="shopping-cart-img">
                    <Link to={process.env.PUBLIC_URL + '/evento/' + single.url}>
                      {single.status === 'venda encerrada' && (
                        <div class="cart-venda-encerrada-img">
                          <span>{single.status}</span>
                        </div>
                      )}
                      <img
                        alt=""
                        src={process.env.PUBLIC_URL + single.urlImagens[0]}
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                  <div className="shopping-cart-title">
                    <h4>
                      <Link
                        to={process.env.PUBLIC_URL + '/evento/' + single.url}
                      >
                        {single.nome}
                      </Link>
                    </h4>
                    <h6>Quantidade: {single.quantity}</h6>
                    <h6>Valor unitário:{priceFormatter(single.valor)}</h6>
                    {single.categoriaIngresso && single.categoriaIngresso ? (
                      <div>
                        {single.categoriaIngresso.map((item, key) => {
                          return (
                            <div key={key}>
                              {item._id === single.categoryId && (
                                <h6>Categoria: {item.nome}</h6>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="shopping-cart-delete">
                    <button
                      onClick={() => dispatch(deleteFromCart(single, addToast))}
                    >
                      <i className="fa fa-times-circle" />
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="shopping-cart-total">
            <h4>Total: {priceFormatter(cartTotalPrice)}</h4>
          </div>
          <div className="shopping-cart-btn btn-hover text-center">
            <Link className="default-btn" to={process.env.PUBLIC_URL + '/cart'}>
              VISUALIZAR CARRINHO
            </Link>
            <Link
              className="default-btn"
              to={process.env.PUBLIC_URL + '/checkout'}
            >
              FINALIZAR
            </Link>
          </div>
        </Fragment>
      ) : (
        <p className="text-center">Carrinho Vazio</p>
      )}
    </div>
  );
};

MenuCart.propTypes = {
  cartData: PropTypes.array,
  currency: PropTypes.object,
  deleteFromCart: PropTypes.func,
};

export default MenuCart;
