import { post, get } from './base/index';

const inscricaoEventoApi = {
  createPedidos: (clientId, body) =>
    post(`/pedido/processar/${clientId}`, body),
  getClientePedidos: (clientId, page, pageSize) =>
    get(`/pedidos/usuario/${clientId}?page=${page}&pageSize=${pageSize}`),
  getPedidoDetalhes: (pedidoId, usuerId) =>
    get(`/pedido/usuario/${pedidoId}/${usuerId}`),
  calcularUsuarioPedido: (clientId, body) =>
    post(`/pedido/calcular/${clientId}`, body),
};

export default inscricaoEventoApi;
