import { createAction } from 'redux-actions';
import cuponsApi from '../../utils/api/cuponsApi';

export const validarCupomRequest = createAction('VALIDAR_LOGIN_REQUEST');
export const validarCupomSuccess = createAction('VALIDAR_LOGIN_SUCCESS');
export const validarCupomError = createAction('VALIDAR_LOGIN_ERROR');

export const resetCupom = createAction('RESET_CUPOM');

export const validarCupom = (codigo, addToast) => async (dispatch) => {
  try {
    dispatch(validarCupomRequest());
    const { data } = await cuponsApi.validarCupom(codigo);
    dispatch(validarCupomSuccess(data));
    if (addToast) {
      addToast('Cupom Adicionado!', {
        appearance: 'success',
        autoDismiss: true,
      });
    }
  } catch (e) {
    console.log(e);
    dispatch(validarCupomError(e.error));
    if ((addToast && e && e.error) || (addToast && e.err)) {
      addToast(e.error || e.err, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }
};

export const cleanCupom = () => async (dispatch) => {
  await dispatch(resetCupom());
};
