import { post, put, get } from './base/index';

const authApi = {
  signup: (form) => post('/signup', form),
  signin: (form) => post('/signin', form),
  signout: () => post('/signout'),
  fetchUser: () => get('/auth/me'),
  forgotPassword: (email) => put('/forgot-password', { email }),
  resetPassword: (form) => put('/reset-password', form),
};

export default authApi;
