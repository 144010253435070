import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { animateScroll } from 'react-scroll';

const Footer = ({
  backgroundColorClass,
  copyrightColorClass,
  spaceLeftClass,
  spaceRightClass,
  footerTopBackgroundColorClass,
  footerTopSpaceTopClass,
  footerTopSpaceBottomClass,
  backgroundImage,
}) => {
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);

  useEffect(() => {
    setTop(100);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <footer
      className={`footer-area ${
        backgroundColorClass ? backgroundColorClass : ''
      } ${spaceLeftClass ? spaceLeftClass : ''} ${
        spaceRightClass ? spaceRightClass : ''
      } ${backgroundImage ? 'bg-img' : ''}`}
      style={{
        backgroundImage: ` ${
          backgroundImage
            ? `url(${process.env.PUBLIC_URL + backgroundImage})`
            : `url()`
        }`,
      }}
    >
      <div
        className={`footer-top text-center ${
          footerTopBackgroundColorClass ? footerTopBackgroundColorClass : ''
        } ${footerTopSpaceTopClass ? footerTopSpaceTopClass : ''}  ${
          footerTopSpaceBottomClass ? footerTopSpaceBottomClass : ''
        }`}
      >
        <div className="container">
          <div className="footer-logo">
            <Link to={process.env.PUBLIC_URL}>
              <h2>SIGA-NOS NAS REDES SOCIAIS</h2>
            </Link>
          </div>
          <div className="footer-social">
            <ul className="redesocial">
              <li>
                <a
                  href="//www.facebook.com/toinhabrasilshow/"
                  rel="noreferrer"
                  target="_blank"
                  title="facebook"
                  style={{ fontSize: 30 }}
                >
                  <i className="fa fa-facebook" />
                </a>
              </li>
              <li>
                <a
                  href="//www.instagram.com/toinhabrasilshow/"
                  rel="noreferrer"
                  target="_blank"
                  title="Instagram"
                  style={{ fontSize: 30 }}
                >
                  <i className="fa fa-instagram" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-bottom text-center">
        <div className="container">
          <div
            className={`copyright-2 ${
              copyrightColorClass ? copyrightColorClass : ''
            }`}
          >
            <p>
              © 2022{' '}
              <a
                href="//www.instagram.com/advanceddigital_/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Desenvolvido por Advanced Digital
              </a>
              | All Rights Reserved
            </p>
          </div>
        </div>
      </div>
      <button
        className={`scroll-top ${scroll > top ? 'show' : ''}`}
        onClick={() => scrollToTop()}
      >
        <i className="fa fa-angle-double-up"></i>
      </button>
    </footer>
  );
};

Footer.propTypes = {
  backgroundColorClass: PropTypes.string,
  copyrightColorClass: PropTypes.string,
  footerLogo: PropTypes.string,
  backgroundImage: PropTypes.string,
  footerTopBackgroundColorClass: PropTypes.string,
  footerTopSpaceBottomClass: PropTypes.string,
  footerTopSpaceTopClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  spaceRightClass: PropTypes.string,
};

export default Footer;
