import { handleActions } from 'redux-actions';
import {
  signinRequest,
  signinSuccess,
  signinError,
  signoutRequest,
  signoutSuccess,
  signoutError,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordError,
  fetchUser,
} from '../actions/authActions';

const defaultState = {
  UID: null,
  isResetPasswordRequested: false,
  isLoggedIn: null,
  isFetching: false,
  error: null,
};

const defaultError = (state, { payload }) => ({
  ...state,
  isFetching: false,
  error: payload,
});

const defaultRequest = (state, { payload }) => ({
  ...state,
  isFetching: true,
  error: null,
});

export default handleActions(
  {
    [signinRequest](state) {
      return {
        ...state,
        UID: false,
        isLoggedIn: false,
        isFetching: true,
        error: null,
      };
    },
    [signinSuccess](state, { payload }) {
      return {
        ...state,
        UID: payload.id,
        isLoggedIn: true,
        isFetching: false,
        error: null,
      };
    },
    [signinError](state, { payload }) {
      return {
        ...state,
        UID: false,
        isLoggedIn: false,
        isFetching: false,
        error: payload,
      };
    },
    [signoutRequest]: defaultRequest,
    [signoutSuccess](state, { payload }) {
      return {
        ...state,
        UID: false,
        isLoggedIn: false,
        isFetching: false,
        error: null,
      };
    },
    [signoutError]: defaultError,
    [forgotPasswordRequest]: defaultRequest,
    [forgotPasswordError]: defaultError,
    [forgotPasswordSuccess](state, { payload }) {
      return {
        ...state,
        isResetPasswordRequested: true,
        isFetching: false,
        error: null,
      };
    },
    [resetPasswordRequest]: defaultRequest,
    [resetPasswordError]: defaultError,
    [resetPasswordSuccess](state, { payload }) {
      return {
        ...state,
        isResetPasswordRequested: false,
        isFetching: false,
        error: null,
      };
    },
    [fetchUser](state, { payload }) {
      return {
        ...state,
        UID: payload.id,
        isLoggedIn: true,
        isFetching: false,
        error: null,
      };
    },
  },
  defaultState
);
