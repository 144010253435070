import img1 from './toinhabrasilshow-arena1.png';
import img2 from './toinhabrasilshow-camarote.png';
import img3 from './toinhabrasilshow-pub.png';
import img4 from './toinhabrasilshow-deck.png';
import img5 from './toinhabrasilshow-arena2-300x225.png';
import img6 from './Foto-397-300x200.png';
import img7 from './toinhabrasilshow-pub-300x225.png';
import img8 from './Foto-407-300x200.png';
import img9 from './Foto-395-300x200.png';
import img10 from './Foto-393-300x200.png';
import img11 from './toinhabrasilshow-deck-300x225.png';
import logo from './logo.png';
import location1 from './location1.png';
import sobreIng from './sobre-ingresso.png';

const images = {
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  logo,
  location1,
  sobreIng,
};
export default images;
