import React from 'react';
import MobileNavMenu from './MobileNavMenu';
import MobileWidgets from './MobileWidgets';

const MobileMenu = () => {
  const closeMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      '#offcanvas-mobile-menu'
    );
    offcanvasMobileMenu.classList.remove('active');
  };

  return (
    <div className="offcanvas-mobile-menu" id="offcanvas-mobile-menu">
      <button
        className="offcanvas-menu-close"
        id="mobile-menu-close-trigger"
        onClick={() => closeMobileMenu()}
      >
        <i className="pe-7s-close"></i>
      </button>
      <div className="offcanvas-wrapper">
        <div className="offcanvas-inner-content">
          <MobileNavMenu />
          <MobileWidgets />
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
