export const NaoLiberadoException = function NaoLiberadoExcaption(
  cpf,
  mensagem
) {
  this.cpf = cpf;
  this.mensagem =
    (mensagem instanceof Array && mensagem.length > 0 && mensagem.join('. ')) ||
    mensagem ||
    null;
  const message = 'não está liberado para consulta';
  this.toString = () =>
    `O CPF ${this.cpf ? this.cpf : 'especificado'} ${message}`;
};

export const NoIdProvidedException = function NoIdProvidedException() {
  this.message = 'Nenhum Id fornecido para consulta';
  this.toString = () => this.message;
};
