import { handleActions } from 'redux-actions';
import {
  createClienteRequest,
  createClienteSuccess,
  createClienteError,
  getClienteRequest,
  getClienteSuccess,
  updateClienteRequest,
  updateClienteSuccess,
  updateClienteError,
  newClienteRequest,
} from '../actions/clienteActions';

const defaultState = {
  cliente: undefined,
  isFetching: false,
  error: null,
};

const defaultRequest = (state) => {
  return {
    ...state,
    isFetching: true,
    error: null,
  };
};

const defaultSucess = (state, { payload }) => {
  return {
    ...state,
    cliente: payload,
    isFetching: false,
    error: null,
  };
};

const defaultError = (state, { payload }) => {
  return {
    ...state,
    isFetching: false,
    error: payload,
  };
};

export default handleActions(
  {
    [createClienteRequest]: defaultRequest,
    [createClienteSuccess]: defaultSucess,
    [createClienteError]: defaultError,
    [getClienteRequest]: defaultRequest,
    [getClienteSuccess]: defaultSucess,
    [updateClienteRequest]: defaultRequest,
    [updateClienteSuccess]: defaultSucess,
    [updateClienteError]: defaultError,
    [newClienteRequest]() {
      return defaultState;
    },
  },
  defaultState
);
