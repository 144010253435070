export * from './exceptions';
export * from './constants';
export * from './session';
/*------- REDUCER HELPERS --------*/
export const defaultRequest = (state) => ({
  ...state,
  isFetching: true,
  error: null,
});
export const defaultSuccess =
  (param) =>
  (state, { payload }) => ({
    ...state,
    [param]: payload,
    isFetching: false,
  });
export const defaultError = (state, { payload }) => ({
  ...state,
  isFetching: false,
  error: payload,
});
/*--------------------------------*/

/**
 *
 * @param {[{name: String, mask: Function}]} fields Fields to format
 * @param {*} formRef Final Form Reference
 */
export const formatFields = (fields, formRef) => {
  const { values } = formRef.current.getState();
  for (let field of fields) {
    const { name, mask } = field;
    values[name] && formRef.current.change(name, mask(values[name]));
  }
};

export const errorHandler = (data, options = {}) => {
  if (!data) return null;
  return (
    data.mensagem ||
    data.erro ||
    data.err ||
    data.error ||
    (!options.pretty &&
      (data.message || data.response?.data?.error || data.response || data))
  );
};

export const successHandler = (data) =>
  data.mensagem || data.message || data.msg || data;

export const csrfHeaders = (csrf) => {
  if (!csrf) return undefined;
  return {
    withCredentials: true,
    headers: {
      'csrf-token': csrf,
    },
  };
};

export const regexFiltrarNaN = /\D+/g;

/** O que esse regex faz?
 *
 * Primeiramente há uma alternação entre dois patterns:
 *  1. Quando tem o número certo de digitos
 *  2. Excedeu 11 digitos (12 3 4567 8911)
 *
 * (1) Esse pattern identifica o DDD, e as duas metades, nomeando-as.
 *
 *    *ddd*: os primeiros 2 digitos do número
 *
 *    *first*: os procedentes 1 à 5 digitos (opcional)
 *
 *    *last*: os últimos 4 digitos (opcional)
 *
 * (2) Esse pattern identifica um número com mais digitos do que o esperado.
 *
 *    *long*: um número com 12 ou mais digitos
 */
export const regexMaskTelefone =
  /^(?<ddd>\d{2})(?<first>\d{1,5})?(?<last>\d{4})?$|(?<long>\d{12,})/;

export const regexMaskRG =
  /^(\d{2})(\d{1,3})?(\d{1,3})?(\d{1})?$|(?<long>\d{10,})/;

export const regexSenha = /(\d{6})/;

export const telefoneMask = (value) => {
  const cleaned = value.replace(regexFiltrarNaN, '');
  const match = cleaned.match(regexMaskTelefone)?.groups;

  if (!match) return cleaned;

  for (let i in match) {
    if (!match[i]) {
      match[i] = '';
    }
  }
  if (match.long) return match.long.substr(0, 11);

  if (match.ddd && !match.first) return match.ddd;

  if (match.last) return `(${match.ddd}) ${match.first}-${match.last}`;

  if (match.first) return `(${match.ddd}) ${match.first}`;

  return cleaned;
};

export const cpfMask = (value) => {
  return value
    .replace(regexFiltrarNaN, '') // não deixa ser digitado nenhuma letra
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1-$2') // captura 3 grupos de número com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
    .replace(/(-\d{2})\d+?$/, '$1'); // captura os dois últimos 2 números, com um - antes dos dois números
};

export const cepMask = (value) => {
  return (
    value
      /* não deixa ser digitado nenhuma letra */
      .replace(regexFiltrarNaN, '')
      /* captura 1 grupos de número com 5 digitos, 
      adicionando um '-' entre eles */
      .replace(/(\d{5})(\d)/, '$1-$2')
      /* captura 2 grupos, um com 2 números e o resto, e elimina o resto */
      .replace(/(-\d{3})\d+?$/, '$1')
  );
};

export const contaMask = (value) => {
  /*const cleaned = value.replace(regexFiltrarNaN, '');
  const match = cleaned.match(regexMaskRG);

  if (!match) return cleaned;
  if (match.groups.long) return match.groups.long.substr(0, 9);

  if (match[1] && !match[2]) return match[1];

  if (match[4]) return `${match[1]}.${match[2]}.${match[3]}-${match[4]}`;

  if (match[3]) return `${match[1]}.${match[2]}.${match[3]}`;

  if (match[2]) return `${match[1]}.${match[2]}`;

  return cleaned;*/
  return value.replace(regexFiltrarNaN, '').replace(/(\d)$/, '-$1');
};

export const priceFormatter = (price, display = true) => {
  if (typeof price === 'string') {
    price = parseFloat(
      price.replace(/[^\d,.]+/, '').replace(/(,)(?!.*,)/, '.')
    );
  }
  if (price) {
    price = price.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    if (!display) {
      price = price.replace(/([R$ ])+/, '');
    }
    return price;
  } else return '';
};
export const senhaMask = (value) =>
  value
    /* 1. Limpa o valor de caracteres não numéricos */
    .replace(regexFiltrarNaN, '')
    /* 2. Limpa os caracteres depois do 6º */
    .replace(/^(\d{1,6})(\d+)?$/, '$1');
