import { JWT_KEY } from './constants';

//TODO: Cookie e HTTPS no local

export const createSession = (data) => {
  const { token, id } = data;
  localStorage.setItem(JWT_KEY, JSON.stringify({ token, id }));
};

export const destroySession = () => {
  localStorage.removeItem(JWT_KEY);
};

export const getSession = () => {
  const item = localStorage.getItem(JWT_KEY);
  if (!item) return null;
  return JSON.parse(item);
};

export const hasSession = () => {
  return !!localStorage.getItem(JWT_KEY);
};

export const getToken = () => {
  const token = getSession()?.token;

  if (token) {
    return token;
  } else {
    return '';
  }
};

export const getUserId = () => {
  const uid = getSession()?.id;

  if (uid) {
    return uid;
  } else {
    return '';
  }
};
