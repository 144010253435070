import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from '../componentes/header/Header';
import Footer from '../componentes/footer/Footer';

const Home = lazy(() => import('../pages/home/Home'));
const ShowDetalhes = lazy(() => import('../pages/show/ShowDetalhes'));
const Cart = lazy(() => import('../pages/cart/Cart'));
const CheckOut = lazy(() => import('../pages/checkout/CheckOut'));
const Wishlist = lazy(() => import('../pages/wishlist/Wishlist'));
const Eventos = lazy(() => import('../pages/eventos/Eventos'));
const PrivateRoute = lazy(() => import('./guards/PrivateRoute'));
const PublicRoute = lazy(() => import('./guards/PublicRoute'));
/*------- Components -------*/
const Contato = lazy(() => import('../pages/contato/Contato'));
const Locacao = lazy(() => import('../pages/locacao/Locacao'));
const RecuperarSenha = lazy(() => import('../pages/recuperar/RecuperarSenha'));
const MudarSenha = lazy(() => import('../pages/recuperar/MudarSenha'));
const ClienteInfo = lazy(() => import('../pages/cliente/ClienteInfo'));
const PedidoDetalhes = lazy(() => import('../pages/pedido/PedidoDetalhes'));
const FinalizarCompra = lazy(() =>
  import('../pages/finalizarCompra/FinalizarCompra')
);
const SobreIngresso = lazy(() =>
  import('../pages/sobreIngresso/SobreIngresso')
);
const MensagemDeCompra = lazy(() =>
  import('../pages/compraFinalizada/MensagemDeCompra')
);
const Login = lazy(() => import('../pages/auth/login/Login'));

function Router() {
  return (
    <div className="main-wrapper">
      <Header />
      <main>
        <Suspense
          fallback={
            <div className="flone-preloader-wrapper">
              <div className="flone-preloader">
                <span></span>
                <span></span>
              </div>
            </div>
          }
        >
          <Routes>
            <Route exact path="/" element={<Home />} />;
            <Route exact path="/eventos" element={<Eventos />} />
            <Route exact path="/locacao" element={<Locacao />} />
            <Route exact path="/contato" element={<Contato />} />
            <Route exact path="/evento/:id" element={<ShowDetalhes />} />
            <Route exact path="/mudar-senha/:token" element={<MudarSenha />} />
            <Route exact path="/wishlist" element={<Wishlist />} />
            <Route exact path="/cart" element={<Cart />} />
            <Route exact path="/sobre-ingressos" element={<SobreIngresso />} />
            <Route element={<PublicRoute wrap />}>
              <Route exact path="/login" element={<Login />} />;
              <Route
                exact
                path="/recuperar-senha"
                element={<RecuperarSenha />}
              />
            </Route>
            <Route element={<PrivateRoute wrap />}>
              <Route exact path="/cliente" element={<ClienteInfo />} />;
              <Route exact path="/pedido/:id" element={<PedidoDetalhes />} />;
              <Route
                exact
                path="/dados-pessoais"
                element={<FinalizarCompra />}
              />
              <Route exact path="/checkout" element={<CheckOut />} />
              ;
              <Route
                exact
                path="/pedido-finalizado"
                element={<MensagemDeCompra />}
              />
              ;
            </Route>
          </Routes>
        </Suspense>
      </main>
      <Footer
        backgroundColorClass="bg-black"
        footerTopBackgroundColorClass="bg-black"
        footerTopSpaceTopClass="pt-80"
        spaceBottomClass="pb-25"
        footerLogo="/assets/img/logo/logo-2.png"
      />
    </div>
  );
}
export default Router;
