import { connect } from 'react-redux';

/*----- Hook Imports -----*/
import { useEffect, useMemo } from 'react';

/*----- Other Imports -----*/
import { hasSession } from '../utils/session';
import { fetchUserData, signout } from '../redux/actions/authActions';

import Loading from '../componentes/form/Loading';

const SessionManager = ({ cliente, auth, dispatch, children }) => {
  const isLoggedIn = useMemo(() => auth.isLoggedIn, [auth]);
  const UID = useMemo(() => auth.UID, [auth]);

  useEffect(() => {
    if (hasSession()) {
      if (isLoggedIn === null || isLoggedIn === true) {
        try {
          dispatch(fetchUserData());
        } catch (e) {
          console.error(e);
          dispatch(signout());
        }
      }
      return;
    }
    if (isLoggedIn === null) {
      dispatch(signout());
    }
    return;
  }, [isLoggedIn, dispatch]);

  const isLoading =
    isLoggedIn === null || UID === null || (isLoggedIn && !cliente);
  return (isLoading && <Loading />) || <>{children}</>;
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  cliente: !!state.cliente,
  state: state,
});

export default connect(mapStateToProps)(SessionManager);
