import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import thunk from 'redux-thunk';
import logger from 'redux-logger';

import rootReducer from '../redux/reducers/rootReducer';

const credentialsPersistConfig = {
  key: 'cart',
  storage,
  whitelist: ['cartData', 'wishlistData'],
};
const persistedReducer = persistReducer(credentialsPersistConfig, rootReducer);
const middlewares = [thunk];

if (process.env.REACT_APP_NODE_ENV === 'development') {
  middlewares.push(logger);
}

const store = createStore(
  persistedReducer,
  compose(applyMiddleware(...middlewares))
);
const persistor = persistStore(store);

export default store;
export { persistor };
