import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const NavMenu = ({ menuWhiteClass, sidebarMenu }) => {
  return (
    <div
      className={` ${
        sidebarMenu
          ? 'sidebar-menu'
          : `main-menu ${menuWhiteClass ? menuWhiteClass : ''}`
      } `}
    >
      <nav>
        <ul>
          <li>
            <Link to={process.env.PUBLIC_URL + '/'}>
              {'Principal'.toUpperCase()}
            </Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + '/eventos'}>
              {'Eventos'.toUpperCase()}
            </Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + '/locacao'}>
              {'Locações'.toUpperCase()}
            </Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + '/contato'}>
              {'Contato'.toUpperCase()}
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
  strings: PropTypes.object,
};

export default NavMenu;
