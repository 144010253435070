import { createAction } from 'redux-actions';
import eventosApi from '../../utils/api/eventosApi';
import { displayError } from './notificacoesActions';

export const getEventoRequest = createAction('GET_EVENTO_REQUEST');
export const getEventoSuccess = createAction('GET_EVENTO_SUCCESS');
export const getEventoError = createAction('GET_EVENTO_ERROR');
export const fetchEventosRequest = createAction('FETCH_EVENTOS_REQUEST');
export const fetchEventosSuccess = createAction('FETCH_EVENTOS_SUCCESS');
export const fetchEventosRangeRequest = createAction(
  'FETCH_EVENTOS_RANGE_REQUEST'
);
export const fetchEventosRangeSuccess = createAction(
  'FETCH_EVENTOS_RANGE_SUCCESS'
);
export const fetchFilteredEventosRequest = createAction(
  'FETCH_FILTERED_EVENTOS_REQUEST'
);
export const fetchFilteredEventosSuccess = createAction(
  'FETCH_FILTERED_EVENTOS_SUCCESS'
);

export const fetchEventos = (page, pageSize) => async (dispatch) => {
  try {
    dispatch(fetchEventosRequest());
    const { data } = await eventosApi.getEventosList(page, pageSize);
    dispatch(fetchEventosSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(displayError(e));
  }
};

export const fetchEventosRange = () => async (dispatch) => {
  try {
    dispatch(fetchEventosRangeRequest());
    const { data } = await eventosApi.getEventosRange();
    dispatch(fetchEventosRangeSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(displayError(e));
  }
};

export const fetchFilteredEventos =
  (dataInicio, dataFim) => async (dispatch) => {
    try {
      dispatch(fetchFilteredEventosRequest());
      const { data } = await eventosApi.getFilteredEventosList(
        dataInicio,
        dataFim
      );
      dispatch(fetchFilteredEventosSuccess(data));
    } catch (e) {
      console.log(e);
      dispatch(displayError(e));
    }
  };

export const getEvento = (id) => async (dispatch) => {
  try {
    dispatch(getEventoRequest());
    const { data } = await eventosApi.readEvento(id);
    dispatch(getEventoSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(getEventoError(e));
    dispatch(displayError(e));
  }
};
